import React from 'react';

const Spinner = () => {
    const circles = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    return (
        <div className="sk-circle align-self-center">
            {circles.map(i => (
                <div className={`sk-circle${i} sk-child`}></div>
            ))}
        </div>
    );
};

export default Spinner;
