import React from 'react';
import cx from 'classnames';
import composePSStyles from '../../styles/composePSStyles';
import BaseButton, { BaseButtonProps } from './BaseButton';

const PrimaryButton = ({ className, ...otherProps } : BaseButtonProps) => {
    const composedClassName = cx(composePSStyles('btn-primary'), className);
    return <BaseButton {...otherProps} className={composedClassName} />;
};

export default PrimaryButton;
