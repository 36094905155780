import React from 'react';
import cx from 'classnames';
import composePSStyles from '../../styles/composePSStyles';
import styles from './styles.module.scss';

type AnchorVariant = {
    as: 'a';
} & JSX.IntrinsicElements['a'];

type ButtonVariant = {
    as: 'button';
} & JSX.IntrinsicElements['button'];

type DivVariant = {
    as: 'div';
} & JSX.IntrinsicElements['div'];

type InputVariant = {
    as: 'input';
} & JSX.IntrinsicElements['input'];

export type BaseButtonProps = AnchorVariant | ButtonVariant | DivVariant | InputVariant;

const BaseButton = ({ as: JSXElement = 'button', className, ...otherProps }: BaseButtonProps) => {
    const composedClassName = cx(styles.baseButton, composePSStyles('btn'), className);
    /*  @ts-expect-error */
    return <JSXElement {...otherProps} className={composedClassName} />;
};

export default BaseButton;
