// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__modal___hDfWQ{position:fixed;top:50%;left:50%;right:auto;bottom:auto;margin:0;padding:0;transform:translate(-50%, -50%);width:100%;outline:none;max-width:600px}.styles-module__modal___hDfWQ .styles-module__body___vinYb{max-height:75vh;overflow-y:auto;padding:2rem}.styles-module__header___Hm3Eg{border-bottom:none !important}.styles-module__close___jYbb8{right:1rem;position:absolute}.styles-module__resultsContainer___DKsFF{height:300px;overflow-y:auto;align-content:center}.ReactModal__Overlay{z-index:1999;position:fixed;top:0;left:0;right:0;bottom:0;opacity:0;transition:opacity 400ms ease-in-out}.ReactModal__Overlay--after-open{opacity:1}.ReactModal__Overlay--before-close{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "styles-module__modal___hDfWQ",
	"body": "styles-module__body___vinYb",
	"header": "styles-module__header___Hm3Eg",
	"close": "styles-module__close___jYbb8",
	"resultsContainer": "styles-module__resultsContainer___DKsFF"
};
export default ___CSS_LOADER_EXPORT___;
