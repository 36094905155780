import React from 'react';
import { createRoot } from 'react-dom/client';
import AppRoot from '../common/AppRoot';
import CommunityFinderContainer from './components/CommunityFinderContainer';

const rootElementId = 'community-finder';
const container = document.getElementById(rootElementId);
if (container) {
    const root = createRoot(container);
    root.render(
        <AppRoot>
            <CommunityFinderContainer />
        </AppRoot>,
    );
}
