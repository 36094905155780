import React, { BaseSyntheticEvent } from 'react';
import Modal from 'react-modal';
import cx from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../../common/components/Buttons';
import PSStylesWrapper from '../../../common/components/PSStylesWrapper';
import composePSStyles from '../../../common/styles/composePSStyles';
import { BaseInput } from '../../../common/components/InputText';
import Spinner from '../../../common/components/Spinner';
import { Community } from '../../../types';
import styles from './styles.module.scss';

interface MinimalCommunity extends Community {
    communityName: string;
}

export interface Props {
    modalVisibility: boolean;
    onCloseModal: () => void;
    onQueryChange: (query: string) => void;
    onNewCommuntySearch: () => void;
    query: string;
    queryResults: MinimalCommunity[];
    loading: boolean;
    displayError: boolean;
}

const CommunityFinderModal = ({
    modalVisibility,
    onCloseModal,
    onQueryChange,
    onNewCommuntySearch,
    query,
    queryResults,
    loading,
    displayError,
}: Props) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onNewCommuntySearch();
        }
    };

    const help = () => {
        //global call
        // @ts-expect-error
        zE.activate();
    };

    Modal.setAppElement('#community-finder'); //This is needed so screen readers don't see main content when modal is opened
    Modal.defaultStyles.overlay = {
        backgroundColor: '#3333339e',
    }; //This is needed as it otherwise overrides site.css
    return (
        <>
            <Modal
                closeTimeoutMS={400}
                isOpen={modalVisibility}
                onRequestClose={onCloseModal}
                contentLabel="Community Finder Info Modal"
                className={styles.modal}
                shouldCloseOnOverlayClick={false}
                bodyOpenClassName="modal-open"
            >
                <PSStylesWrapper>
                    <div
                        className={composePSStyles(
                            'modal-dialog',
                            'modal-dialog-scrollable',
                            'modal-dialog-centered',
                            'modal-xl',
                            'text-center',
                        )}
                    >
                        <div className={composePSStyles('modal-content')}>
                            <div className={cx(styles.header, composePSStyles('modal-header'))}>
                                <button
                                    type="button"
                                    className={cx(styles.close, composePSStyles('close'))}
                                    onClick={onCloseModal}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className={cx(styles.body, composePSStyles('modal-body'))}>
                                <h2 className={composePSStyles('mb-3')}>Find Your Community</h2>
                                <h4 className={composePSStyles('text-left')}>Employer/Organization Finder</h4>
                                <BaseInput
                                    type="query"
                                    name="query"
                                    placeholder="Employer/Organization Name"
                                    autoComplete="query"
                                    defaultValue={query}
                                    onChange={(event: BaseSyntheticEvent) => onQueryChange(event.target.value)}
                                    onKeyDown={handleKeyDown}
                                    value={query}
                                    className={cx(styles.query, composePSStyles('w-100'))}
                                />
                                <SecondaryButton
                                    id="searchAgainButton"
                                    as="button"
                                    className={composePSStyles('mt-2', 'w-100', 'mb-4')}
                                    onClick={() => onNewCommuntySearch()}
                                >
                                    Search Again
                                </SecondaryButton>
                                <div className={styles.resultsContainer}>
                                    {displayError && (
                                        <>
                                            <h4 className={composePSStyles('alert', 'alert-danger')}>
                                                Oops! Something went wrong
                                                <br />
                                                Please try again.
                                            </h4>
                                        </>
                                    )}
                                    {loading && <Spinner />}
                                    {!loading && queryResults.length > 0 && (
                                        <>
                                            <h4 className={composePSStyles('text-left')}>Matching Communities:</h4>
                                            {queryResults.map((community, index) => (
                                                <div key={index}>
                                                    <a
                                                        href={`/login?communityId=${community['communityId']}`}
                                                        className={cx(
                                                            styles.query,
                                                            composePSStyles('text-decoration-none'),
                                                        )}
                                                    >
                                                        <PrimaryButton
                                                            id="closeResultsModalButton"
                                                            as="button"
                                                            className={composePSStyles('w-100', 'my-2')}
                                                        >
                                                            {community.communityName}
                                                        </PrimaryButton>
                                                    </a>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                    {!loading && !displayError && queryResults.length === 0 && (
                                        <div>
                                            <h4>We Didn't Find a Match</h4>
                                            <p>Try searching again and make sure to check your spelling.</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={composePSStyles('modal-footer, text-center')}>
                                <div className={composePSStyles('mb-2')}>
                                    Already have an account?{' '}
                                    <a href="#" onClick={onCloseModal}>
                                        Sign in
                                    </a>
                                </div>
                                <div className={composePSStyles('mb-4')}>
                                    Need more help?{' '}
                                    <a href="#" onClick={help}>
                                        Contact Us
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </PSStylesWrapper>
            </Modal>
        </>
    );
};

export default CommunityFinderModal;
