import React from 'react';
import Modal from 'react-modal';
import styles from './styles.module.scss';
import cx from 'classnames';
import composePSStyles from '../../../common/styles/composePSStyles';
import PSStylesWrapper from '../../../common/components/PSStylesWrapper';
import { SecondaryButton } from '../../../common/components/Buttons';

export interface Props {
    modalVisibility: boolean;
    onCloseModal: () => void;
}

const InfoModal = ({ modalVisibility, onCloseModal }: Props) => {
    const help = () => {
        //global call
        // @ts-expect-error
        zE.activate();
    };
    Modal.setAppElement('#community-finder'); //This is needed so screen readers don't see main content when modal is opened
    Modal.defaultStyles.overlay = {
        backgroundColor: '#3333339e',
    }; //This is needed as it otherwise overrides site.css
    return (
        <>
            <Modal
                closeTimeoutMS={400}
                isOpen={modalVisibility}
                onRequestClose={onCloseModal}
                contentLabel="Community Finder Info Modal"
                className={styles.modal}
                shouldCloseOnOverlayClick={false}
                bodyOpenClassName="modal-open"
            >
                <PSStylesWrapper>
                    <div
                        className={composePSStyles(
                            'modal-dialog',
                            'modal-dialog-scrollable',
                            'modal-dialog-centered',
                            'modal-xl',
                        )}
                    >
                        <div className={composePSStyles('modal-content')}>
                            <div className={cx(styles.header, composePSStyles('modal-header'))}>
                                <button
                                    type="button"
                                    className={cx(styles.close, composePSStyles('close'))}
                                    onClick={onCloseModal}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className={cx(styles.body, composePSStyles('modal-body'))}>
                                <h2>How It Works:</h2>
                                <h4 className={composePSStyles('mt-4')}>
                                    If you have access to Perkspot, you should have received an email from your employer
                                    or organization
                                </h4>
                                <ul>
                                    <li>
                                        Try searching the email inbox associated with your employer or organization.
                                    </li>
                                    <li>
                                        In the email, you'll find information on how to log in and create an account.
                                    </li>
                                </ul>
                                <h4 className={composePSStyles('mt-5')}>I still can't log in</h4>
                                <ul>
                                    <li>
                                        If your employer or organization provides you with an email address, there's a
                                        good chance that will be your email to log in. If you need to reset your
                                        password, select "Forgot my Password?" to create a new one.
                                    </li>
                                </ul>
                                <p className={composePSStyles('mt-2')}>
                                    <b>
                                        Need more help?{' '}
                                        <a href="#" onClick={help}>
                                            Contact Us
                                        </a>
                                    </b>
                                </p>
                            </div>
                            <div className={composePSStyles('modal-footer, text-center')}>
                                <SecondaryButton
                                    id="loginSubmitButton"
                                    as="button"
                                    className={composePSStyles('mb-4')}
                                    onClick={onCloseModal}
                                >
                                    Close
                                </SecondaryButton>
                            </div>
                        </div>
                    </div>
                </PSStylesWrapper>
            </Modal>
        </>
    );
};

export default InfoModal;
