// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__modal___tvRfu{position:fixed;top:50%;left:50%;right:auto;bottom:auto;margin:0;padding:0;transform:translate(-50%, -50%);width:100%;outline:none;max-width:600px}.styles-module__modal___tvRfu .styles-module__body___uNGcb{max-height:75vh;overflow-y:auto;padding:2rem}.styles-module__header___gSlx9{border-bottom:none !important}.styles-module__close___kCvCT{right:1rem;position:absolute}.ReactModal__Overlay{z-index:1999;position:fixed;top:0;left:0;right:0;bottom:0;opacity:0;transition:opacity 400ms ease-in-out}.ReactModal__Overlay--after-open{opacity:1}.ReactModal__Overlay--before-close{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "styles-module__modal___tvRfu",
	"body": "styles-module__body___uNGcb",
	"header": "styles-module__header___gSlx9",
	"close": "styles-module__close___kCvCT"
};
export default ___CSS_LOADER_EXPORT___;
