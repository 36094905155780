import React from 'react';
import composePSStyles from '../styles/composePSStyles';

type Props = {
    children: React.ReactNode;
};

const PSStylesWrapper = (props: Props) => {
    return <div className={composePSStyles('styles-wrapper')}>{props.children}</div>;
};

export default PSStylesWrapper;
