import React, { BaseSyntheticEvent, ForwardedRef } from 'react';
import styles from './styles.module.scss';
import cx from 'classnames';
import composePSStyles from '../../styles/composePSStyles';
import { forwardRef } from 'react';

type Props = {
    className?: string;
    type: string;
    placeholder?: string;
    defaultValue?: string;
    onChange?: (event: BaseSyntheticEvent) => void;
    onBlur?: (event: BaseSyntheticEvent) => void;
    value?: string;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    ref?: ForwardedRef<HTMLInputElement>;
    autoComplete?: string;
    name?: string;
};

const BaseInput = forwardRef(({ className, ...otherProps } : Props, ref) => {
    const composedClassName = cx(styles.inputText, className, composePSStyles('p-2'));
    return <input {...otherProps} className={composedClassName} ref={ref as ForwardedRef<HTMLInputElement>} />;
});

export default BaseInput;
