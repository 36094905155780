// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__infoLink___q3r5G{font-size:14px;font-weight:normal;font-style:normal}.styles-module__finderContainer___UQT9B{max-width:550px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoLink": "styles-module__infoLink___q3r5G",
	"finderContainer": "styles-module__finderContainer___UQT9B"
};
export default ___CSS_LOADER_EXPORT___;
