// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__baseButton___Uzre0{text-decoration:none}.styles-module__baseButtonhover___Z_ZTC,.styles-module__baseButtonfocus___zWfhJ{text-decoration:underline !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"baseButton": "styles-module__baseButton___Uzre0",
	"baseButtonhover": "styles-module__baseButtonhover___Z_ZTC",
	"baseButtonfocus": "styles-module__baseButtonfocus___zWfhJ"
};
export default ___CSS_LOADER_EXPORT___;
