// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__password___UZhYp{display:flex;flex-direction:row;align-items:center;padding:10px;gap:10px;width:38px;height:42px;background:#eee;box-shadow:inset 0px 1px 2px rgba(0,0,0,.05);flex:none;order:2;align-self:stretch;flex-grow:0;position:absolute;cursor:pointer;border:1px solid var(--ps-perx-border)}.styles-module__passwordInput___YkFCQ{width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"password": "styles-module__password___UZhYp",
	"passwordInput": "styles-module__passwordInput___YkFCQ"
};
export default ___CSS_LOADER_EXPORT___;
