import React from 'react';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { toast } from 'react-toastify';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            cacheTime: 0,
            // onError can be overridden locally
            onError: _error => toast.error(`Something went wrong...`),
            retry: 1,
            retryDelay: 1000
        },
    },
});

function AppRoot({ children }) {
    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={window.stateStore}>{children}</Provider>
        </QueryClientProvider>
    );
}

export default AppRoot;
