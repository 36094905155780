import React, { BaseSyntheticEvent, useState } from 'react';
import cx from 'classnames';
import composePSStyles from '../../../common/styles/composePSStyles';
import PSStylesWrapper from '../../../common/components/PSStylesWrapper';
import BaseInput from '../../../common/components/InputText/BaseInput';
import { PrimaryButton } from '../../../common/components/Buttons';
import { getCommunities } from '../../../common/services/PscApi';
import { Community } from '../../../types';
import ResultsModal from '../ResultsModal';
import InfoModal from '../InfoModal';
import styles from './styles.module.scss';

interface MinimalCommunity extends Community {
    communityName: string;
}

const CommunityFinderContainer = () => {
    const [query, setQuery] = useState('');
    const [queryResults, setQueryResults] = useState<MinimalCommunity[]>([]);
    const [lastQuery, setLastQuery] = useState('');
    const [infoModalVisible, setInfoModalVisible] = useState(false);
    const [resultsModalVisible, setResultsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [displayError, setDisplayError] = useState(false);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            communtySearch();
        }
    };

    const communtySearch = () => {
        if (query != '' && query.toLowerCase() !== lastQuery.toLowerCase()) {
            setResultsModalVisible(true);
            setLoading(true);
            setDisplayError(false);
            setLastQuery(query);

            getCommunities(query)
                .then(response => {
                    setQueryResults(response.data);
                    setLoading(false);
                })
                .catch(e => {
                    setQueryResults([]);
                    setDisplayError(true);
                    setLoading(false);
                });
        }
    };

    const launchInfoModal = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setInfoModalVisible(true);
    };

    return (
        <PSStylesWrapper>
            <div className={styles.finderContainer}>
                <h3 className={composePSStyles('mt-4')}>
                    Employer or Organization Finder{' '}
                    <a
                        href="#"
                        onClick={e => {
                            launchInfoModal(e);
                        }}
                        className={cx(styles.infoLink, composePSStyles('ml-1'))}
                    >
                        More Info
                    </a>
                </h3>
                <div className={composePSStyles('d-flex')}>
                    <BaseInput
                        type="query"
                        name="query"
                        placeholder="Type your Company or Org. name"
                        autoComplete="query"
                        onChange={(event: BaseSyntheticEvent) => setQuery(event.target.value)}
                        value={query}
                        onKeyDown={handleKeyDown}
                        className={cx(styles.query, composePSStyles('mr-2', 'flex-grow-1'))}
                    />
                    <PrimaryButton
                        id="communitySearchButton"
                        as="button"
                        className={composePSStyles('my-2')}
                        onClick={() => communtySearch()}
                    >
                        Search
                    </PrimaryButton>
                </div>
            </div>

            <InfoModal
                modalVisibility={infoModalVisible}
                onCloseModal={() => {
                    setInfoModalVisible(false);
                    setLastQuery('');
                }}
            />
            <ResultsModal
                modalVisibility={resultsModalVisible}
                onCloseModal={() => {
                    setResultsModalVisible(false);
                    setLastQuery('');
                }}
                onQueryChange={(query: string) => setQuery(query)}
                onNewCommuntySearch={() => {
                    communtySearch();
                }}
                query={query}
                queryResults={queryResults}
                loading={loading}
                displayError={displayError}
            />
        </PSStylesWrapper>
    );
};

export default CommunityFinderContainer;
