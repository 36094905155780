import React from 'react';
import cx from 'classnames';
import composePSStyles from '../../styles/composePSStyles';
import BaseButton, { BaseButtonProps } from './BaseButton';

const PrimaryUtilityButton = ({ className, ...otherProps } : BaseButtonProps) => {
    const composedClassName = cx(composePSStyles('btn-outline-secondary'), className);
    return <BaseButton {...otherProps} className={composedClassName} />;
};

export default PrimaryUtilityButton;
